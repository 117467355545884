import { combineReducers } from "redux";
import routeReducer from "./routeReducer";
import vehiclesReducer from "./vehicleReducer";
import metricsReducer from "./metricsReducer";
import popupReducer from "./popupReducer";
import vehicleSelectionReducer from "./vehicleSelectionReducer";
import vehicleListReducer from "./vehicleListReducer";
import settingsReducer from "./settingsReducer";
import relayReducer from "./relayReducer";
import timestampWeatherReducer from "./timestampWeatherReducer";
import logsReducer from "./logsReducer";
import fleetReducer from "./fleetReducer";
import DateSelectionReducer from "./dateSelectionReducer";
import adminReducer from "./adminReducer";
import lastOnlineReducer from "./lastOnlineReducer";
import customerGroupPerEmailReducer from "./customerGroupPerEmailReducer";
import notificationsReducer from "./notificationsReducer";

const rootReducer = combineReducers({
  vehicleRoutes: routeReducer,
  vehicle: vehiclesReducer,
  metrics: metricsReducer,
  popupView: popupReducer,
  vehicleSelection: vehicleSelectionReducer,
  vehicleList: vehicleListReducer,
  settings: settingsReducer,
  relay: relayReducer,
  timestampWeather: timestampWeatherReducer,
  logs: logsReducer,
  fleet: fleetReducer,
  dateSelections: DateSelectionReducer,
  emails: adminReducer,
  lastOnline: lastOnlineReducer,
  customerGroupPerEmail: customerGroupPerEmailReducer,
  notifications: notificationsReducer,
});

export default rootReducer;
