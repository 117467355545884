import { ReactElement, ReactNode } from "react";
export const NOTIFICATIONS_LOADING = "NOTIFICATIONS_LOADING";
export const NOTIFICATIONS_FAILURE = "NOTIFICATIONS_FAILURE";
export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";

export type Notifications = {
  Notifications: Array<NotificationsObject>;
};

export type NotificationsObject = {
  alert_config_id: number;
  customer_name: string;
  alert_name: string;
  email_address: string;
  frequency: string | null;
  custom_threshold: number | null;
  is_active: boolean;
  sub_customer: string | null;
  custom_days: number | null;
};

export interface NotificationsLoading {
  type: typeof NOTIFICATIONS_LOADING;
}

export interface NotificationsFailure {
  type: typeof NOTIFICATIONS_FAILURE;
}

export interface NotificationsSuccess {
  type: typeof NOTIFICATIONS_SUCCESS;
  payload: Array<NotificationsObject>;
}

export type NotificationsDispatchTypes =
  | NotificationsLoading
  | NotificationsFailure
  | NotificationsSuccess;
