import {
  NotificationsDispatchTypes,
  NOTIFICATIONS_LOADING,
  NOTIFICATIONS_FAILURE,
  NOTIFICATIONS_SUCCESS,
  NotificationsObject,
} from "../actions/notificationsActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  notifications?: Array<NotificationsObject>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const notificationsReducer = (
  state: DefaultStateInterface = defaultState,
  action: NotificationsDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case NOTIFICATIONS_FAILURE:
      return {
        loading: false,
      };
    case NOTIFICATIONS_LOADING:
      return {
        loading: true,
      };
    case NOTIFICATIONS_SUCCESS:
      return {
        loading: false,
        notifications: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type NotificationsRootState = ReturnType<typeof notificationsReducer>;

export default notificationsReducer;
